@import "../scss/variables";


/*---------- Header-styles ----------*/

/*Header-Color*/

.color-header {
	.main-header-center .form-control {
		color:$white;
		&::placeholder{
			color: $white !important;
			opacity: .3;
		}
	}
	.hor-header .header-brand1 {
		margin-top: 4px;
	}
}

	  
.color-header {
	.main-header-center .form-control::placeholder {
		color: $white !important;
		opacity: .3;
	  }
	.app-header, .hor-header {
	  background: $primary-1;
	  border-bottom:1px solid rgba(255,255,255, .1);
	}
	.app-header .header-right-icons .nav-link.icon {
	  background: $black-1;
	}
	.app-header .header-right-icons .nav-link.icon:hover {
      background: $black-1 !important;
    }
	.app-sidebar__toggle:hover {
      color: #fff;
	}
  
	.nav-link.toggle, .header-right .nav-link.icon {
	  background: $black-1;
	  box-shadow: rgba(4, 4, 7 , .1) 0px 2px 3px;
	}
  
	.animated-arrow {
	  background: none;
  
	  span {
		background: #e7e7f7;
  
		&:before, &:after {
		  background: #e7e7f7;
		}
	  }
	}
	&.active .animated-arrow span {
		background-color: transparent;
	}
	.header .nav-link.icon i, .header-right .nav-link.icon:hover i {
	  color: #fff !important;
	}
  
	.header {
	  .nav-link {
		color: #e7e7f7 !important;
  
		&:hover {
		  color: $white;
		}
	  }
  
	  .form-inline .form-control {
		border: 1px solid $white-05 !important;
		background: rgba(0, 0, 0, 0.08);
		color: $white;
	  }
	}
	.header .form-inline {
	  .btn i{
		color: $white !important;
		opacity: .5;
	  }
  
	  .form-control::placeholder {
		color: $white !important;
		opacity: .3;
	  }
	}
	.app-sidebar__toggle{
		background: rgba(0, 0, 0, 0.08);
		color: #fff;
		box-shadow: rgba(4, 4, 7 , .1) 0px 2px 3px;
		a{
			color:$white;
		}
	}
	.nav-link.icon{
		background: rgba(0, 0, 0, 0.08);
		box-shadow: rgba(4, 4, 7 , .1) 0px 2px 3px;
	}
  }

  @media (min-width: 992px) {
	.color-header {
		.hor-header.header .header-brand .header-brand-img{
			&.desktop-lgo{
				display: none;
			}
			&.dark-logo{
				display: block;
				margin-top: 5px;
			}
		}
	}
  }
  @media (max-width: 767px) {
	.color-header {
	  .hor-header.header .header-brand-img {
		&.mobile-logo {
		  display: none !important;
		}
		&.darkmobile-logo {
			display: block;
		}
	  }
	}
  }
  @media (max-width: 1024px) and (min-width: 768px){
	.color-header {
		.hor-header.header .header-brand-img {
			&.desktop-lgo, &.mobile-logo{
				display: none !important;
			}
			&.dark-logo{
				display: block !important;
				margin-top: 5px;
			}
		}

	}
  }
  @media (max-width: 767px) {
	  .color-header{
		  .app-header .header-brand1 .header-brand-img.light-logo1{
			display: none !important;
		  }
		  .app-header .header-brand1 .header-brand-img.desktop-logo{
			display: block !important;
		  }
	  } 
  }
  .dark-mode.color-header {
	.app-header, .hor-header {
	  background: $primary-1;
	}
  
	.header .nav-link.icon {
		background: rgba(0, 0, 0, 0.08);
		box-shadow: rgba(4, 4, 7 , .1) 0px 2px 3px;
	}
  
	.header .form-inline .form-control {
	  border: 1px solid $white-05 !important;
	  color: $white;
	}
  }
  
  
  /*Header-dark*/
  
  .dark-header {
	.app-header, .hor-header {
	  background: #17263a;
	  border-bottom:1px solid rgba(255,255,255, .1);
	}
	.hor-header .header-brand1 {
		margin-top: 4px;
	}
  
	.nav-link.toggle, .header-right .nav-link.icon {
	  background: rgba(255, 255, 255, 0.05);
	}
  
	.animated-arrow {
	  background: none;
  
	  span {
		background: #e7e7f7;
  
		&:before, &:after {
		  background: #e7e7f7;
		}
	  }
	}
	&.active .animated-arrow span {
	  background-color: transparent;
    }
	.header.hor-header .nav-link.icon, .header-right-icons .nav-link.icon:hover {
	  color: #e7e7f7 !important;
	  background: #1f324c;
	}
  
	.app-header.header .nav-link:hover {
	  color: $white;
	}
	.app-header .header-right-icons .nav-link.icon {
	  background: #1f324c;
	}
	.app-header .header-right-icons .nav-link.icon:hover {
      background: #1f324c !important;
    }
	.app-sidebar__toggle:hover {
      color: #fff;
	}
  
	.header .main-header-center .form-control {
	  border: 1px solid $white-05 !important;
	  background: #1f324c;
	  color: $white;
	}

	.header .form-inline {
	  .btn i{
		color: $white;
		opacity: .5;
	  }
  
	  .form-control::placeholder {
		color: $white;
		opacity: .3;
	  }
	}
  
	.header.hor-header .header-brand-img {
	  &.desktop-logo {
		display: none;
	  }
  
	  &.light-logo {
		display: block;
	  }
	}
	.app-sidebar__toggle{
		background: #1f324c;
		color: #fff;
		a{
			color:$white;
		}
	}
	.nav-link.icon i{
		color: $white !important;
	}
  }
  @media (max-width: 768px){
	.dark-header {
		.app-header .header-brand1 .header-brand-img{
			  &.light-logo1{
				display: none !important;
				margin-top: 5px;
			  }
			  &.desktop-logo{
				display: block;
			  }
		  }
		.navresponsive-toggler span {
				background: #1f324c !important;
				color: #fff !important;
		}
	  }
  }
  @media (min-width: 992px) {
	.dark-header {
		.hor-header.header .header-brand .header-brand-img{
			&.desktop-lgo{
				display: none;
			}
			&.dark-logo{
				display: block;
				margin-top: 5px;
			}
		}
	}
  }
  @media (max-width: 768px) {
	.dark-header .hor-header.header .header-brand-img.desktop-logo-1 {
	  display: none !important;
	}
  }
  @media (max-width: 1024px) and (min-width: 768px){
	.dark-header {
		.hor-header.header .header-brand-img {
			&.desktop-lgo, &.mobile-logo{
				display: none !important;
			}
			&.dark-logo{
				display: block !important;
				margin-top: 5px;
			}
		}

	}
  }
  
  .light-mode.dark-header .hor-header .mobile-header.hor-mobile-header{
		background: #30304d !important;
  }
  
  /*Header-light*/
  
  .dark-mode.light-header {
	.app-header, .hor-header {
	  background: $white;
	  border-bottom: 1px solid rgb(236, 240, 250);
	}
	.app-sidebar{
		box-shadow: none;
	}
	.header .nav-link.icon, .header-right .nav-link.icon {
	  background: #ffffff;
	  box-shadow: rgba(4, 4, 7, .1) 0px 2px 3px;
	}
	.animated-arrow {
		span {
		  background: #5c678f;
	
		  &:before, &:after {
			background: #5c678f;
		  }
		}
	  }
	  &.active .animated-arrow span {
		  background-color: transparent;
	  }
	.header .form-inline .form-control {
	  border: 1px solid transparent !important;
	  background: #f1f5ff;
	  color: $black;
	}
  
	.hor-header .header-brand-img.desktop-logo-1 {
	  display: none;
	}
  
	.drop-profile a {
	  color: #171a29;
	}
  
	.hor-header .header-brand-img.desktop-logo {
	  display: block;
	}
	.header .nav-link.icon i{
		color: #17263a;
	}
	.app-sidebar__toggle{
		background: #fff;
		box-shadow: rgba(4, 4, 7, .1) 0px 2px 3px;
		a{
			color: #555b95;
		}
	}
  }
  @media (max-width: 575.98px){
	.dark-mode.light-header .header .form-inline .form-control{
		background: #191d43;
	}
  }
  @media (max-width: 767px) {
	.dark-mode.light-header{ 
		.hor-header .header-brand-img.desktop-logo {
		  display: none;
		}
		.app-header .header-brand1 .header-brand-img{
			&.desktop-logo{
				display: none;
			}
			&.light-logo1{
				display: block !important;
			}
		}
		.mobile-header {
			background-color: #ffffff;
		}
		.mobile-header .header-brand-img.desktop-logo {
			display: none !important;
		}
		.mobile-header .header-brand-img.desktop-logo.mobile-light {
			display: block !important;
		}
	}	
  }
  @media (max-width: 768px) and (min-width: 568px){
	.app.dark-mode.light-header {
		.app-header  .header-brand .header-brand-img{
			  &.desktop-lgo{
				display: block;
				margin-top: 5px;
			  }
			  &.dark-logo, &.mobile-logo, &.darkmobile-logo{
				display: none !important;
			  }
		  }
	  }
  }
  @media (max-width: 1024px) and (min-width: 768px){
	.dark-mode.light-header {
		.hor-header.header .header-brand-img {
			&.dark-logo, &.mobile-logo{
				display: none !important;
			}
			&.desktop-lgo{
				display: block !important;
				margin-top: 5px;
			}
		}

	}
  }


  /*Header-Gradient-header*/
  	
.gradient-header {
	.hor-header .header-brand1 {
		margin-top: 4px;
	}
	.app-header, .hor-header {
	  background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
	  border-bottom:1px solid rgba(255,255,255, .1);
	}
	.main-header-center .form-control {
		color:$white;
		&::placeholder{
			color: $white !important;
			opacity: .3;
		}
	}
	.hor-header .header-right-icons .nav-link.icon {
		background: rgba(0, 0, 0, 0.08);
	}
	.nav-link.toggle, .header-right-icons .nav-link.icon {
	  background: #ffffff;
	}
	
	.animated-arrow {
	  background: none;
  
	  span {
		background: #e7e7f7;
  
		&:before, &:after {
		  background: #e7e7f7;
		}
	  }
	}
	&.active .animated-arrow span {
		background-color: transparent;
	}
	.header .nav-link.icon i, .header-right-icons .nav-link.icon:hover i {
	  color: #e7e7f7 !important;
	}
  
	.app-header.header .nav-link:hover {
	  color: $white;
	}
	.app-header .header-right-icons .nav-link.icon {
	  background: rgba(0, 0, 0, 0.08);
	}
	.app-header .header-right-icons .nav-link.icon:hover {
      background: rgba(0, 0, 0, 0.08) !important;
    }
	.app-sidebar__toggle:hover {
      color: #fff;
	}
  
	.header {
	  .nav-link {
		color: #e7e7f7 !important;
  
		&:hover {
		  color: $white;
		}
	  }
  
	  .main-header-center .form-control {
		border: 1px solid $white-1 !important;
		background: rgba(0, 0, 0, 0.08) !important;
		color: $white;
	  }
	  .main-header-center .form-control:placeholder {
		color: $white !important;
		opacity: .5;
		}
	}
	.app-sidebar__toggle{
		background: rgba(0, 0, 0, 0.08);
		color: #fff;
		box-shadow: rgba(4, 4, 7 , .1) 0px 2px 3px;
		a{
			color:$white;
		}
	}
	.nav-link.icon{
		background: rgba(0, 0, 0, 0.08);
	}
  }
  @media (min-width: 992px) {
	.gradient-header {
		.hor-header.header .header-brand1 .header-brand-img{
			&.desktop-logo{
				display: none !important;
			}
			&.light-logo{
				display: block;
			}
		}
	}
  }
  @media (max-width: 768px) and (min-width: 575.98px){
	.gradient-header {
		.app-header  .header-brand .header-brand-img{
			  &.dark-logo{
				display: block;
				margin-top: 5px;
			  }
			  &.desktop-lgo, &.mobile-logo, &.darkmobile-logo{
				display: none !important;
			  }
		  }
	  }
  }
  @media (max-width: 767px) {
	.gradient-header{
		.app-header .header-brand1 .header-brand-img {
			&.desktop-logo {
			display: block;
			}
	
			&.light-logo1{
			display: none !important;
			}
	  	}
		
		.navresponsive-toggler span{
			background: rgba(0, 0, 0, 0.08) !important;
			color: #fff !important;
		}
  
	  .hor-header.header .header-brand-img {
		&.mobile-logo {
		  display: none !important;
		}
  
		&.darkmobile-logo {
			display: block;
			margin-top: 3px;
		}
	  }
	}
  }
  @media (max-width: 1024px) and (min-width: 768px){
	.gradient-header {
		.hor-header.header .header-brand-img {
			&.desktop-lgo, &.mobile-logo{
				display: none !important;
			}
			&.dark-logo{
				display: block !important;
				margin-top: 5px;
			}
		}

	}
  }
  .dark-mode.gradient-header {
	.app-header, .hor-header {
	  background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
	}
  
	.header .nav-link.icon{
		background: rgba(0, 0, 0, 0.08);
		box-shadow: rgba(4, 4, 7 , .1) 0px 2px 3px;
	}
  
	.header .form-inline .form-control {
	  border: 1px solid $white-05 !important;
	  color: $white;
	}
  }
  
  
  
  /*---------- Leftmenu-styles ----------*/
  
  /*Light-menu*/
  .light-menu{
	.app-sidebar {
		background: $white;
		.app-sidebar__user{
		  border-bottom: $border;
		}
		.side-menu .slide a {
		  color: #9eabc0;
		}
		.side-menu .sidemenu_icon{
		  color: #fff !important;
		}
		.app-sidebar__user {
		  .user-pic .avatar-xxl{
			  border: 3px solid #ebeff8;
		  }
		  .user-pic::before{
			  border: 2px solid #fff;
		  }
		  .user-info h5{
			  color: #263871;
		  }
		}
		.Annoucement_card{
		  background: #ebeff8;
		  .title, .text-white {
			  color: #263871 !important;
		  }
		}
	  }
	  .slide a.active .sidemenu_icon, .side-menu__item:hover .sidemenu_icon{
		  color: $white;
	  }
	  .slide-menu li {
		&.active > a, &:hover > a {
		  color: $primary-1 !important;
		}
	  }
	  .side-item.side-item-category{
		  opacity: .7 !important;
	  }
  }
  .dark-mode.light-menu {
	.app-sidebar {
	  background: $white !important;
	  .app-sidebar__user{
		border-bottom: $border;
	  }
	  .side-menu .slide a {
		color: #9eabc0;
	  }
	  .side-menu .sidemenu_icon{
		color: #9eabc0;
	  }
	  .app-sidebar__user {
		.user-pic .avatar-xxl{
			border: 3px solid #ebeff8;
		}
		.user-pic::before{
			border: 2px solid #fff;
		}
		.user-info h5{
			color: #9eabc0;
		}
	  }
	  .Annoucement_card{
		background: #ebeff8;
		.title, .text-white {
			color: #263871 !important;
		}
	  }
	}
	.slide a.active .sidemenu_icon, .side-menu__item:hover .sidemenu_icon{
		color: $white;
	}
	.slide-menu li {
	  &.active > a, &:hover > a {
		color: $primary-1 !important;
	  }
	}
  }
  
  @media (min-width: 768px) {
	.light-menu{
		.app-sidebar__logo {
			border-bottom: $border;
			border-right: $border;
		}
		.app-sidebar .app-sidebar__logo .header-brand-img{
			&.desktop-lgo{
				display: block;
				margin: 0 auto;
				margin-top: 5px;
			}
			&.dark-logo{
				display: none;
			}
		}
		&.sidenav-toggled {
			.app-sidebar .app-sidebar__logo .header-brand-img{
				&.desktop-lgo{
					display: none;
				}
			}
		}
		&.sidenav-toggled.sidenav-toggled1 {
			.app-sidebar .app-sidebar__logo .header-brand-img{
				&.desktop-lgo{
					display: block;
					margin: 0 auto;
					margin-top: 5px;
				}
				&.dark-logo{
					display: none;
				}
			}
		}
	} 
	.dark-mode.light-menu{
		.side-header {
			border-bottom: 1px solid #eaedf1;
			border-right: 1px solid #eaedf1;
			top: -4px;
		}
		.side-menu h3 {
			color: #000;
		}
		.side-menu__item:hover, .side-menu__item:focus {
			background: #f9fbfd !important;
		}
		.side-menu .side-menu__icon, .dark-mode .side-menu__item, .dark-mode .slide-item {
			color: #6e84a3;
		}
		.side-menu__label{
			color: #6e84a3;
		}
		.side-menu__item.active:hover, .side-menu__item.active:focus {
			color: #ffffff;
			background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
		}
		.side-menu__item.active .side-menu__icon {
            color: #ffffff !important;
		}
		.app-sidebar .side-header .header-brand-img{
			&.light-logo1{
				display: block !important;
				margin: 0 auto;
				margin-top: 5px;
			}
			&.desktop-logo{
				display: none !important;
			}
		}
		&.sidenav-toggled {
			.app-sidebar .side-header .header-brand-img{
				&.desktop-logo{
					display: none !important;
				}
			}
		}
		&.sidenav-toggled.sidenav-toggled-open {
			.app-sidebar .side-header .header-brand-img{
				&.light-logo1{
					display: block !important;
					margin: 0 auto;
					margin-top: 5px;
				}
				&desktop-logo{
					display: none !important;
				}
			}
		}
	} 
  }
.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo{
	display: none !important;
} 
.app.sidebar-mini.dark-mode.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1{
	display: block !important;
} 
  
  /*Color-menu*/
  
  .color-menu {
	.app-sidebar{
		border-right:1px solid rgba(255, 255, 255, 0.1);
	  }
	.app-sidebar .side-header{
	  background: $primary-1;
	  border-right:1px solid rgba(255, 255, 255, 0.1);
	  .side-item.side-item-category {
		color: $white;
		opacity: .3;
	  }
	  .side-menu .side-menu__icon, .side-menu .side-menu__item{
		color: #cccef5 !important;
	  }
	  .slide a.active .sidemenu_icon, .side-menu__item:hover .sidemenu_icon{
		color: $white !important;
		opacity: inherit;
	  }
	  .side-menu .slide a.active, .side-menu .slide a:hover{
		color: $white !important;
		opacity: inherit;
	  }
	  .slide .side-menu__item.active, .slide .side-menu__item:hover{
		background: rgba(0, 0, 0, .1);
	  }
	  .side-menu__item.active:hover, .side-menu__item.active:focus{
        background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
	  }	
	  .side-menu__item:hover .side-menu__label, .side-menu__item:hover .side-menu__icon, .side-menu__item:hover .angle{
		color: $white !important;
		opacity: inherit;
	  }
	  .app-sidebar__user{
		border-bottom: 1px solid $white-1;
		.user-info h5{
			color: $white;
		}
		.app-sidebar__user-name.text-muted{
			color: $white !important;
			opacity: .7;
		}
	  }
	  .app-sidebar__user .user-pic .avatar-xxl {
		border: 3px solid $white;
	  }
	}
  }
  
  @media (min-width: 768px) {
	.color-menu {
	  .side-header{
		border-bottom: 1px solid $white-1;
		border-right: 1px solid $white-1;
	  }
	  .app-sidebar .side-header .header-brand-img{
			&.desktop-logo{
				display: block;
				margin: 0 auto;
			}
			&.light-logo1{
				display: none;
			}
		}
		&.sidenav-toggled {
			.app-sidebar .side-header .header-brand-img{
				&.desktop-logo{
					display: none;
				}
				&.toggle-logo{
					display: block !important;
				}
				&.light-logo, &.light-logo1, &.mobile-light {
				    display: none;
				}
			}
		}
		&.sidenav-toggled.sidenav-toggled-open {
			.app-sidebar .side-header .header-brand-img{
				&.desktop-logo{
					display: block;
					margin: 0 auto;
				}
				&.toggle-logo{
					display: none !important;
				}
				&.light-logo1{
					display: none !important;
				}
			}
		}
	}
  }
  
  .dark-mode.color-menu {
	.app-sidebar {
	  background: $primary-1;
	  .side-menu .slide a{
		color: $white;
		opacity: .7;
	  }
	}
	.app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
		border: 3px solid $white;
	}
	.app-sidebar .app-sidebar__user .user-pic::before{
		border: 2px solid $white;
	}
	.side-item.side-item-category {
	  color: $white;
	  opacity: .5;
	}
  
	.app-sidebar ul li a {
	  color: $white;
	  opacity: .8;
	}
  
	.slide-menu li {
	  &.active > a, &:hover > a {
		color: $white !important;
		opacity: inherit;
	  }
	}
  
	.app-sidebar {
	  .slide-menu .slide-item:hover, .side-menu__item.active .side-menu__icon {
		color: $white !important;
		opacity: inherit;
	  }
	}
  
	.slide.is-expanded .side-menu__item {
	  color: $white !important;
	  opacity: inherit;
	}
  }
  
  @media (min-width: 768px) {
	.dark-mode.color-menu .app-sidebar__logo {
	  border-bottom: 1px solid $white-1;
	  border-right: 1px solid $white-1;
	}
  }

  /*dark-menu*/
  
  .dark-menu{
	.app-sidebar{
	  background: #30304d !important;
	  border-right:1px solid rgba(255, 255, 255, 0.1);
	  .side-item.side-item-category {
		color: $white;
		opacity: .3;
	  }
	  .side-header{
		background: #30304d !important;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	  }
	  .side-menu h3 {
		color: rgba(255, 255, 255, 0.3);
	  }
	  .side-menu .side-menu__icon {
		color: #dedefd !important;
	  }
	  
	  .side-menu .slide a{
		color: #dedefd !important;
	  }
	  .side-menu__item {
		color: #dedefd !important;
	  }
	  .slide-item {
		color: #dedefd !important;
	  }
	  .side-menu__item:hover, .side-menu__item:focus {
		background: #2e2e4a !important;
	  }
	  .slide a.active .side-menu__icon, .side-menu__item:hover .side-menu__icon{
		color: #dedefd !important;
		opacity: inherit;
	  }
	  .side-menu .slide a.active, .side-menu .slide a:hover{
		color: #dedefd !important;
		opacity: inherit;
	  }
	  .side-menu__item:hover .side-menu__label, .side-menu__item:hover .side-menu__icon, .side-menu__item:hover .angle{
		color: #dedefd !important;
		opacity: inherit;
	  }
	  .side-menu__item:hover .side-menu__icon, .side-menu__item:hover .side-menu__label, .side-menu__item:focus .side-menu__icon, .side-menu__item:focus .side-menu__label {
         color: #dedefd !important;
	  }
	  .app-sidebar__user{
		border-bottom: 1px solid $white-1;
		.user-info h5{
			color: $white;
		}
		.app-sidebar__user-name.text-muted{
			color: $white !important;
			opacity: .5;
		}
	  }
	}
  }
  
  @media (min-width: 768px) {
	.dark-menu {
	  .side-header{
		border-bottom: 1px solid $white-1;
		border-right: 1px solid $white-1;
	  }
	  .app-sidebar .side-header .header-brand-img{
			&.desktop-logo{
				display: block;
				margin: 0 auto;
			}
			&.light-logo1{
				display: none;
			}
		}
		&.sidenav-toggled {
			.app-sidebar .side-header .header-brand-img{
				&.desktop-logo{
					display: none;
				}
				&.light-logo1{
					display: block;
				}
			}
		}
		&.sidenav-toggled.sidenav-toggled-open {
			.app-sidebar .side-header .header-brand-img{
				&.desktop-logo{
					display: block;
					margin: 0 auto;
				}
				&.light-logo1{
					display: none !important;
				}
			}
		}
	}
  }


  /*Graident-Color*/
  .gradient-menu {
	.app-sidebar {
	  background: linear-gradient(to bottom, #8971ea, #7f72ea, #7574ea, #6a75e9, #5f76e8) !important;
	  .side-item.side-item-category {
		color: $white;
		opacity: .3;
	  }
	  .side-header{
		background: #8371ea;
		border-bottom: 1px solid #8688dc;
		border-right: 1px solid #8688dc;
	    top: -5px;
	  }
	  .side-menu .slide a{
		color: #ccc9ec;
		opacity: .7;
	  }
	  .side-menu h3 {
		color: #ccc9ec;
	  }
	  .side-menu .side-menu__icon {
		color: #ccc9ec;
	  }
	  .slide-menu li .slide-item:before {
		color: #c7c9eb;
	  }
	  .side-menu__item:hover .side-menu__icon, .side-menu__item:hover .side-menu__label, .side-menu__item:focus .side-menu__icon, .side-menu__item:focus .side-menu__label {
		color: #ffffff !important;
	  }
	  
	  .side-menu .slide a{
		color: #ccc9ec;
	  }
	  .side-menu__item {
		color: #ccc9ec;
	  }
	  .slide-item {
		color: #ccc9ec;
	  }
	  .side-menu__item:hover, .side-menu__item:focus {
		background: #7e7de4 !important;
	  }
	  .side-menu__item.active{
		background: rgba(0,0,0,0.08) !important;
	  }
	  
	  .side-menu__item.active:hover, .side-menu__item.active:focus{
        background: rgba(0,0,0,0.08) !important;
	  }	
	  
	  .slide a.active .side-menu__icon, .side-menu__item:hover .side-menu__icon{
		color: $white !important;
		opacity: inherit;
	  }
	  .side-menu .slide a.active, .side-menu .slide a:hover{
		color: $white !important;
		opacity: inherit;
	  }
	  .side-menu__item:hover .side-menu__label, .side-menu__item:hover .side-menu__icon, .side-menu__item:hover .angle{
		color: $white !important;
		opacity: inherit;
	  }
	  .app-sidebar__user{
		border-bottom: 1px solid $white-1;
		.user-info h5{
			color: $white;
		}
		.app-sidebar__user-name.text-muted{
			color: $white !important;
			opacity: .7;
		}
	  }
	  .app-sidebar__user .user-pic .avatar-xxl {
		border: 3px solid $white;
	  }
	  .Annoucement_card{
		background: #4747e2;
		.title, .text-white, .btn-outline {
			color: $white !important;
		}
	  }
	}
  }
  
  @media (min-width: 768px) {
	.gradient-menu {
	  .app-sidebar {
		border-bottom: 1px solid $white-1;
		border-right: 1px solid $white-1;
	  }
	  .app-sidebar .side-header .header-brand-img{
			&.desktop-logo{
				display: block;
				margin: 0 auto;
				margin-top: 5px;
			}
			&.light-logo1{
				display: none;
			}
		}
		&.sidenav-toggled {
			.app-sidebar .side-header .header-brand-img{
				&.desktop-logo{
					display: none;
				}
				&.toggle-logo{
					display: block !important;
					margin-top: 5px;
				}
				&.light-logo, &.light-logo1, &.mobile-light {
				    display: none;
				}
			}
		}
		&.sidenav-toggled.sidenav-toggled-open {
			.app-sidebar .side-header .header-brand-img{
				&.desktop-logo{
					display: block;
					margin: 0 auto;
					margin-top: 5px;
				}
				&.toggle-logo{
					display: none !important;
				}
				&.light-logo1{
					display: none !important;
				}
			}
		}
	}
  }
  
  
  .dark-mode.gradient-menu {
	.app-sidebar {
	 background: linear-gradient(to bottom, #8971ea, #7f72ea, #7574ea, #6a75e9, #5f76e8) !important;
	 .side-menu .slide a{
		color: $white;
		opacity: .7;
	  }
	}
	.app-sidebar .app-sidebar__user .user-pic .avatar-xxl {
		border: 3px solid $white;
	}
	.app-sidebar .app-sidebar__user .user-pic::before{
		border: 2px solid $white;
	}
	.side-item.side-item-category {
	  color: $white;
	  opacity: .5;
	}
  
	.app-sidebar ul li a {
	  color: $white;
	  opacity: .8;
	}
  
	.slide-menu li {
	  &.active > a, &:hover > a {
		color: $white !important;
		opacity: inherit;
	  }
	}
  
	.app-sidebar {
	  .slide-menu .slide-item:hover, .side-menu__item.active .side-menu__icon {
		color: $white !important;
		opacity: inherit;
	  }
	}
  
	.slide.is-expanded .side-menu__item {
	  color: $white !important;
	  opacity: inherit;
	}
  }
  
  @media (min-width: 768px) {
	.dark-mode.gradient-menu .app-sidebar__logo {
	  border-bottom: 1px solid $white-1;
	  border-right: 1px solid $white-1;
	}
  }


  /*---------- Horizontalmenu-styles ----------*/
  
  /*Light-hormenu*/
  
  .light-hormenu {
	.horizontal-main.hor-menu {
	  background: $white;
	}
  
	.horizontalMenu > .horizontalMenu-list > li > {
	  a {
		color: #171a29;
	  }
  
	  ul.sub-menu:before {
		border-bottom: 10px solid $border;
	  }
	}
  
	.mega-menubg:before {
	  border-bottom: 10px solid $border;
	}
  }
  
  @media only screen and (max-width: 991px) {
	.light-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: $white;
	  }
  
	  .horizontalMenu > .horizontalMenu-list > li > {
		a {
		  color: #171a29;
		}
  
		ul.sub-menu {
		  background-color: #ffffff;
  
		  > li > a {
			color: #171a29;
		  }
		}
	  }
	}
  }
  
  .dark-mode.light-hormenu {
	.horizontal-main.hor-menu {
	  background: $white;
	}
  
	.horizontalMenu > .horizontalMenu-list > li > a {
	  color: #171a29;
	}
  
	&.light-header .horizontal-main.hor-menu {
	  box-shadow: none;
	}
  }
  
  @media only screen and (max-width: 991px) {
	.dark-mode.light-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: $white;
	  }
	  .horizontalMenu > .horizontalMenu-list > li > {
		a {
		  color: #171a29;
		}
  
		ul.sub-menu {
		  background-color: #f1f5ff;
  
		  > li > a {
			color: #171a29;
		  }
		}
	  }
	  .horizontal-main.hor-menu{
		.horizontalMenu>.horizontalMenu-list{
			border-right: 1px solid #e9ebfa;
		}
		.horizontalMenu>.horizontalMenu-list>li>a{
			border-bottom-color:#e9ebfa;
			background: #fff !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i{
			color:#576482;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu, .mega-menubg{
			background: #f1f4fb !important;
		}
		.sub-menu li a:before, .mega-menubg li a:before{
			color:#728096;
		}
		// .sub-menu li a.active:before, .sub-menu li a:hover:before, .mega-menubg li a.active:before, .mega-menubg li a:hover:before{
		// 	color:#fff !important;
		// }
		// .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active, .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover{
		// 	color:#fff !important;
		// }
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a, 
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
		.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a{
			color:#576482;
			background: #f1f4fb !important;
		}
		.horizontalMenu h3{
			color: #263871;
		}
		
	  }
	}
  }
  
  
  /*color-hormenu*/
  
  .color-hormenu {
	.horizontal-main.hor-menu {
	  background: $primary-1;
	}
  
	.horizontalMenu > .horizontalMenu-list > li > a {
	  color: $white;
	  opacity: 0.8;
	  border-right: 1px solid rgba(255, 255, 255, 0.1);
  
	  > .fa {
		color: $white;
		opacity: 0.8;
	  }
  
	  &.active, &:hover, > .fa {
		color: $white !important;
		opacity: inherit;
		background: #6159bb !important;
	  }
	}
	.horizontalMenu>.horizontalMenu-list>li:last-child>a{
		border-right: 0px;
	}
	.hor-menu .horizontalMenu > .horizontalMenu-list > li{
	  &:first-child {
		 border-left: 1px solid rgba(255, 255, 255, 0.1);
	  }
	  &:last-child {
		 border-right: 1px solid rgba(255, 255, 255, 0.1);
	  }
	}
  }
  @media only screen and (max-width: 991px) {
	.color-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: $primary-1;
	  }
	  .horizontalMenu > .horizontalMenu-list > li > {
		a {
		  color: $white;
		  opacity: 0.8;
		  border-right: 1px solid rgba(255, 255, 255, 0.1);
		}
		.hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child {
			border-left: 1px solid rgba(255, 255, 255, 0.1);
		}
  
		ul.sub-menu > li > a {
		  color: $white;
		  opacity: 0.8;
  
		  &.active, &:hover {
			color: $white !important;
			opacity: inherit;
		  }
		}
	  }
	  .horizontal-main.hor-menu{
		.horizontalMenu>.horizontalMenu-list{
			border-right: 1px solid rgba(255, 255, 255, 0.07);
		}
		.horizontalMenu>.horizontalMenu-list>li>a{
			border-bottom-color:rgba(255, 255, 255, 0.07);
		}
		.horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i{
			color:rgba(255, 255, 255, 0.4);
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu, .mega-menubg{
			background: #625abf !important;
		}
		.sub-menu li a:before, .mega-menubg li a:before{
			color:rgba(255, 255, 255, 0.3);
		}
		.sub-menu li a.active:before, .sub-menu li a:hover:before, .mega-menubg li a.active:before, .mega-menubg li a:hover:before{
			color:#fff !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active, .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover{
			color:#fff !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a, 
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
		.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a{
			color:rgba(255, 255, 255, 0.6);
			background-color: #625abf !important;
		}
		.horizontalMenu h3{
			color: $white;
		}
		
	  }
	  
	}
  }
  
  .dark-mode.color-hormenu {
	.horizontal-main.hor-menu {
	  background: $primary-1;
	  box-shadow:none;
	}
  }
  @media only screen and (max-width: 991px) {
	.dark-mode.color-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: $primary-1;
	  }
	}
	
   }
  .dark-mode.gradient-header .mobile-header {
    border-bottom: 1px solid rgb(100, 76, 252) !important;
  }	
  
  
  /*Gradient-hormenu*/
  
  .gradient-hormenu {
	.horizontal-main.hor-menu {
	  background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
	}
  
	.horizontalMenu > .horizontalMenu-list > li > a {
	  color: $white;
	  opacity: 0.8;
	  border-right: 1px solid rgba(255, 255, 255, 0.1);
  
	  > .fa {
		color: $white;
		opacity: 0.8;
	  }
  
	  &.active, &:hover{
		background: rgba(255, 255, 255, 0.04) !important;
	  }
  
	  &.active, &:hover, > .fa {
		color: $white !important;
		opacity: inherit;
	  }
	}
	.horizontalMenu>.horizontalMenu-list>li:last-child>a{
		border-right: 0px;
	}
	.hor-menu .horizontalMenu > .horizontalMenu-list > li{
	  &:first-child {
		 border-left: 1px solid rgba(255, 255, 255, 0.1);
	  }
	  &:last-child {
		 border-right: 1px solid rgba(255, 255, 255, 0.1);
	  }
	}
  }
  
  @media only screen and (max-width: 991px) {
	.gradient-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
	  }
	  .horizontalMenu > .horizontalMenu-list > li > {
		a {
		  color: $white;
		  opacity: 0.8;
		}
  
		ul.sub-menu > li > a {
		  color: $white;
		  opacity: 0.8;
  
		  &.active, &:hover {
			color: $white !important;
			opacity: inherit;
		  }
		}
	  }
	  .horizontal-main.hor-menu{
		.horizontalMenu>.horizontalMenu-list{
			border-right: 1px solid rgba(255, 255, 255, 0.07);
		}
		.horizontalMenu>.horizontalMenu-list>li>a{
			border-bottom-color:rgba(255, 255, 255, 0.07);
		}
		.horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i{
			color:rgba(255, 255, 255, 0.4);
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu, .mega-menubg{
			background: rgba(0, 0, 0, 0.08) !important;
		}
		.sub-menu li a:before, .mega-menubg li a:before{
			color:rgba(255, 255, 255, 0.3);
		}
		.sub-menu li a.active:before, .sub-menu li a:hover:before, .mega-menubg li a.active:before, .mega-menubg li a:hover:before{
			color:#fff !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active, .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover{
			color:#fff !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a, 
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
		.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a{
			color:rgba(255, 255, 255, 0.6);
		}
		.horizontalMenu h3{
			color: $white;
		}
		
	  }
	}
  }
  
  .dark-mode.gradient-hormenu {
	.horizontal-main.hor-menu {
	  background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
	  box-shadow:none;
	}
  }
  @media only screen and (max-width: 991px) {
	.dark-mode.gradient-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: linear-gradient(to bottom, #8971ea, #7f72ea, #7574ea, #6a75e9, #5f76e8) !important;
	  }
	}
  }
  

  .dark-mode{
	&.color-header.gradient-menu .app-sidebar{
		box-shadow: none;
	}
	&.gradient-header.gradient-header .app-sidebar{
		box-shadow: none;
	}
	&.color-menu.color-header .app-sidebar{
		box-shadow: none;
	}
	&.light-menu.color-header .app-sidebar{
		box-shadow: none;
	}
  }

 /*dark-hormenu*/
  
 .dark-hormenu {
	.horizontal-main.hor-menu {
	  background: #17263a;
	}
  
	.horizontalMenu > .horizontalMenu-list > li > a {
	  color: $white;
	  opacity: 0.8;
	  border-right: 1px solid #45484c;
  
	  > .fa {
		color: $white;
		opacity: 0.8;
	  }
  
	  &.active, &:hover {
		background: #1f324c !important;
	  }
  
	  &.active, &:hover, > .fa {
		color: $white !important;
		opacity: inherit;
	  }
	}
	.horizontalMenu>.horizontalMenu-list>li:last-child>a{
		border-right: 0px;
	}
	.hor-menu .horizontalMenu > .horizontalMenu-list > li{
	    &:first-child {
			border-left: 1px solid #45484c;
		} 
	    &:last-child {
			border-right: 1px solid #45484c;
		} 
	}
  }
  @media only screen and (max-width: 991px) {
	.dark-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: #17263a;
	  }
	  .horizontalMenu > .horizontalMenu-list > li > {
		a {
		  color: $white;
		  opacity: 0.8;
		}
  
		ul.sub-menu > li > a {
		  color: $white;
		  opacity: 0.8;
  
		  &.active, &:hover {
			color: $white !important;
			opacity: inherit;
		  }
		}
	  }
	  .horizontal-main.hor-menu{
		.horizontalMenu>.horizontalMenu-list{
			border-right: 1px solid rgba(255, 255, 255, 0.07);
		}
		.horizontalMenu>.horizontalMenu-list>li>a{
			border-bottom-color:rgba(255, 255, 255, 0.07);
		}
		.horizontalMenu>.horizontalMenu-list>li>.horizontalMenu-click>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li .horizontalMenu-click02>i,
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu.sub-menu>li .horizontalMenu-click03>i{
			color:rgba(255, 255, 255, 0.4);
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu, .mega-menubg{
			background: #1b2c42 !important;
		}
		.sub-menu li a:before, .mega-menubg li a:before{
			color:rgba(255, 255, 255, 0.3);
		}
		.sub-menu li a.active:before, .sub-menu li a:hover:before, .mega-menubg li a.active:before, .mega-menubg li a:hover:before{
			color:#fff !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a.active, .horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a:hover{
			color:#fff !important;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>a, 
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>ul.sub-menu>li>ul.sub-menu>li>a,
		.horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a{
			color:rgba(255, 255, 255, 0.6);
			background-color: #1b2c42;
		}
		.horizontalMenu h3{
			color: $white;
		}
		
	  }
	}
  }
  
  .dark-mode.dark-hormenu {
	.horizontal-main.hor-menu {
	  background: $primary-1;
	  box-shadow:none;
	}
  }
  @media only screen and (max-width: 991px) {
	.dark-mode.dark-hormenu {
	  &.active .horizontalMenu > .horizontalMenu-list {
		background: $primary-1;
	  }
	}
	
   }
  

.gradient-header.color-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}
.dark-header.color-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}
.color-header.color-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}


.gradient-header.gradient-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}
.dark-header.gradient-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}
.color-header.gradient-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}

.gradient-header.dark-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}
.dark-header.dark-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}
.color-header.dark-menu .app-sidebar{
	border-right: 1px solid rgba(255,255,255, 0.05);
	box-shadow: none;
}

@media (min-width: 991px) {
	.dark-hormenu.gradient-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.dark-hormenu.dark-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.dark-hormenu.color-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.color-hormenu.gradient-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.color-hormenu.dark-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.color-hormenu.color-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.gradient-hormenu.gradient-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.gradient-hormenu.dark-header .header.hor-header{
		border-bottom: 1px solid rgba(255, 255, 255, 0.07);
	}
	.gradient-hormenu.color-header .header.hor-header{
		border-bottom: 1px solid transparent;
	}
}
@media (max-width: 767px){
	.dark-mode .mobile-header {
		background-color: #30304d;
		border: 1px solid transparent;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}
.color-header .header-brand-img.desktop-logo{
	display: none;
}

.color-header .mobile-header.hor-mobile-header{
	background: #6259ca;
}
@media (max-width: 767px){
	.color-header .mobile-header .header-brand-img.desktop-logo {
		display: block;
	}
	.color-header .mobile-header .header-brand-img.mobile-light {
		display: none !important;
	}
}
.color-header .hor-header .header-right-icons .nav-link.icon {
    background: #6159bb;
}
.color-header .main-header-center .form-control {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255,255,255, 0.08);
}

.color-menu .app-sidebar .side-menu .h3 {
	color: #b3aee8 !important;
}
.color-header .light-logo, .color-header .light-logo1, .color-header .mobile-light {
    display: block;
}

.color-header .app-sidebar .side-header .header-brand-img.light-logo{
    display: none;
}
.color-header.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
    display: block;
}
.light-header .hor-header .header-brand-img.light-logo {
    display: none;
}
.light-header .form-control {
    color: #dedefd !important;
    background-color: #ffffff;
    border: 1px solid #ecf0fa;
}
.light-header .hor-header .header-right-icons .nav-link.icon {
    background: #fff;
}
.light-header .nav-link.icon i {
    color: #6e84a3 !important;
}
.light-header .header .dropdown .nav-link.icon:hover {
    background: none !important;
}
.light-header.light-hormenu .horizontalMenu > .horizontalMenu-list > li > a {
    color: #25252a;
    border-right: 1px solid #eaedf1;
}
.light-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active, .light-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #5e2dd8;
    background: #fff !important;
}
.light-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active, .light-header.light-hormenu.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #6259ca;
	 background: #fff !important;
}
.light-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child {
    border-left: 1px solid #eaedf1;
}
.light-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li:last-child {
    border-right: 1px solid #eaedf1;
}
.light-header.color-hormenu .horizontalMenu > .horizontalMenu-list > li > a.active, .light-header.color-hormenu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #fff !important;
    opacity: inherit;
    background: #6159bb !important;
}

.light-header.gradient-hormenu .horizontalMenu > .horizontalMenu-list > li > a.active, .light-header.gradient-hormenu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #fff !important;
    opacity: inherit;
    background: rgba(255, 255, 255, 0.04) !important;
}


.color-header.light-hormenu .horizontalMenu > .horizontalMenu-list > li > a {
    color: #25252a;
    border-right: 1px solid #eaedf1;
}
.color-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active, .color-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #5e2dd8;
    background: #fff !important;
}
.color-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active, .color-header.light-hormenu.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #6259ca;
	 background: #fff !important;
}
.color-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child {
    border-left: 1px solid #eaedf1;
}
.color-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li:last-child {
    border-right: 1px solid #eaedf1;
}
.color-header.color-hormenu .horizontalMenu > .horizontalMenu-list > li > a.active, .color-header.color-hormenu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #fff !important;
    opacity: inherit;
    background: #6159bb !important;
}

.color-header.gradient-hormenu .horizontalMenu > .horizontalMenu-list > li > a.active, .color-header.gradient-hormenu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #fff !important;
    opacity: inherit;
    background: rgba(255, 255, 255, 0.04) !important;
}



.gradient-header.light-hormenu .horizontalMenu > .horizontalMenu-list > li > a {
    color: #25252a;
    border-right: 1px solid #eaedf1;
}
.gradient-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active, .gradient-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #5e2dd8;
    background: #fff !important;
}
.gradient-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active, .gradient-header.light-hormenu.hor-menu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #6259ca;
	 background: #fff !important;
}
.gradient-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li:first-child {
    border-left: 1px solid #eaedf1;
}
.gradient-header.light-hormenu .hor-menu .horizontalMenu > .horizontalMenu-list > li:last-child {
    border-right: 1px solid #eaedf1;
}
.gradient-header.color-hormenu .horizontalMenu > .horizontalMenu-list > li > a.active, .gradient-header.color-hormenu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #fff !important;
    opacity: inherit;
    background: #6159bb !important;
}
.gradient-header.gradient-hormenu .horizontalMenu > .horizontalMenu-list > li > a.active, .gradient-header.gradient-hormenu .horizontalMenu > .horizontalMenu-list > li > a:hover {
    color: #fff !important;
    opacity: inherit;
    background: rgba(255, 255, 255, 0.04) !important;
}

.light-header .app-sidebar__toggle {
    color: #5b6e88;
    background: #fff;
}
.light-header .app-header .header-right-icons .nav-link.icon {
    background: #fff;
}
.light-header .app-sidebar__toggle:hover {
    color: #6e84a3;
}

.light-header.light-menu .side-header {
    border-bottom: 1px solid #eaedf1;
	background: #fff;
}

.light-header .navresponsive-toggler span {
	background: #fff !important;
	color: #000 !important;
}
.light-header.light-menu .app-sidebar {
    background: #fff;
}
.light-header.light-menu .side-menu h3 {
    color: #000;
}
.light-header.light-menu .side-menu__item {
    color: #6e84a3;
}
.light-header.light-menu .side-menu .side-menu__icon {
    color: #9eabc0;
}

.light-header.light-menu .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: block !important;
	}
	&.desktop-logo{
		display: none !important;
	}
}
.light-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: none !important;
	}
}

.light-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: block !important;
	}
}	




.color-header.light-menu .side-header {
    border-bottom: 1px solid #eaedf1;
	background: #fff;
}

.color-header.light-menu .app-sidebar {
    background: #fff;
}
.color-header.light-menu .side-menu h3 {
    color: #000;
}
.color-header.light-menu .side-menu__item {
    color: #6e84a3;
}
.color-header.light-menu .side-menu .side-menu__icon {
    color: #9eabc0;
}

.color-header.light-menu .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: block !important;
	}
	&.desktop-logo{
		display: none !important;
	}
}
.color-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: none !important;
	}
}

.color-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: block !important;
	}
}

.color-header .navresponsive-toggler span {
		background: #5a52ba !important;
		color: #fff !important;
}



.gradient-header.light-menu .side-header {
    border-bottom: 1px solid #eaedf1;
	background: #fff;
}

.gradient-header.light-menu .app-sidebar {
    background: #fff;
}
.gradient-header.light-menu .side-menu h3 {
    color: #000;
}
.gradient-header.light-menu .side-menu__item {
    color: #6e84a3;
}
.gradient-header.light-menu .side-menu .side-menu__icon {
    color: #9eabc0;
}

.gradient-header.light-menu .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: block !important;
	}
	&.desktop-logo{
		display: none !important;
	}
}
.gradient-header.light-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: none !important;
	}
}

.gradient-header.light-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img{
	&.light-logo1{
		display: block !important;
	}
}


.dark-mode .apexcharts-radialbar-track.apexcharts-track path {
	stroke: #21203a;
}
.dark-mode .apex-charts text {
    fill: #fff;
}
.light-mode .apexcharts-radialbar-track.apexcharts-track path {
	stroke: #f4f4f4;
}
.light-mode .apex-charts text {
    fill: #000;
}
.light-mode.color-header.color-menu .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}

.light-mode.color-header.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}
.light-mode.color-header.color-menu.sidenav-toggled .app-sidebar .side-header .header-brand-img.light-logo {
    display: none;
}	
.light-mode.color-menu .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}

.app.sidebar-mini.color-menu .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}
.app.sidebar-mini.color-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}

.app.sidebar-mini.dark-menu .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}
.app.sidebar-mini.dark-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}

.app.sidebar-mini.gradient-menu .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}
.app.sidebar-mini.gradient-menu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1{
	display: none !important;
}

.app.sidebar-mini.dark-mode.light-menu .side-header {
    background: #ffffff;
}


.dark-header .mobile-header.hor-mobile-header{
	background: #30304d;
}
.gradient-header .mobile-header.hor-mobile-header{
	background: linear-gradient(to right, #9e88f5 0%, #6259ca 100%) !important;
}
@media (max-width: 767px){
	.dark-header .mobile-header {
		.header-brand-img {
			&.desktop-logo {
				display: block;
			}
			&.mobile-light {
				display: none !important;
			}
		}
	}	
}	

@media (max-width: 767px){
	.gradient-header .mobile-header {
		.header-brand-img {
			&.desktop-logo {
				display: block;
			}
			&.mobile-light {
				display: none !important;
			}
		}
	}	
}	

.dark-mode.gradient-hormenu .hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active, 
.dark-mode.gradient-hormenu .hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
    background:rgba(255, 255, 255, 0.08)  !important;
}
.dark-mode.light-hormenu .hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active, 
.dark-mode.light-hormenu .hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
    background:transparent  !important;
}
.dark-mode.light-header .form-control {
    border: 1px solid #ecf0fa;
}

/*--Boxed--*/
@media (min-width: 1025px) {
	body.layout-boxed {
		background: #cfd1e2;
	}
	body.layout-boxed.dark-mode {
		background:#414165
	}
	.layout-boxed{
		.page {
			width: 1300px;
			margin: 0 auto;
			background:#f2f3f9;
			position: relative;
			display: -ms-flexbox;
			display: -webkit-box;
			display: flex;
			-ms-flex-direction: column;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			flex-direction: column;
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			-webkit-box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
			box-shadow: rgba(104, 113, 123, 0.3) 0px 5px 0px 0px, rgba(104, 113, 123, 0.3) 0px 5px 25px 0px;
		}
		.app-header.header{
			width: 1300px !important;
    		margin: 0 auto;
		}
		.app-sidebar{
			left: auto !important; 
		}
		.side-header {
			left: auto !important; 
			right: auto !important; 
		}
		h3.number-font{
			font-size: 1.8rem;
		}
		.sticky-pin .horizontal-main.hor-menu{
			width: 1300px !important;
		}
		.hor-content .container, .hor-header .container, .horizontal-main.hor-menu .container {
			max-width: 95%;
		}
	}
	.dark-mode.layout-boxed .page{
		background:#22223d;
		-webkit-box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
			box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 5px 25px 0px;
	}
}

/*--- Scrollabel-header ----*/
.scrollable-layout{
	.app-header, .mobile-header{
		position: absolute !important;
	}
	.app-sidebar{
		position: absolute;
	}
	.side-header{
		position: absolute;
	}
	.page {
		position: relative;
	}
	.sticky.sticky-pin{
		position: absolute;
	}
} 
	